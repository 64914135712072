import React, { useState } from "react";
import { firestore } from "../../../../firebase";
import Loader from "react-loader-spinner";
import MoreSettingsPopUp from "./moreSettings";
import _ from "lodash";

const StoreForm = ({ storeInfo, merchantKey }) => {
  const opening_hours_format = {
    monday: storeInfo?.opening_hours?.monday || [`${storeInfo?.store_start_time}-${storeInfo?.store_end_time}`],
    tuesday: storeInfo?.opening_hours?.tuesday || [`${storeInfo?.store_start_time}-${storeInfo?.store_end_time}`],
    wednesday: storeInfo?.opening_hours?.wednesday || [`${storeInfo?.store_start_time}-${storeInfo?.store_end_time}`],
    thursday: storeInfo?.opening_hours?.thursday || [`${storeInfo?.store_start_time}-${storeInfo?.store_end_time}`],
    friday: storeInfo?.opening_hours?.friday || [`${storeInfo?.store_start_time}-${storeInfo?.store_end_time}`],
    saturday: storeInfo?.opening_hours?.saturday || [`${storeInfo?.store_start_time}-${storeInfo?.store_end_time}`],
    sunday: storeInfo?.opening_hours?.sunday || [`${storeInfo?.store_start_time}-${storeInfo?.store_end_time}`],
  };

  const form = {
    store_name: storeInfo?.store_name || "",
    store_start_time: storeInfo?.store_start_time || "",
    store_end_time: storeInfo?.store_end_time || "",
    is_active: storeInfo?.is_active || false,
    theme: {
      color_primary: storeInfo?.theme?.color_primary || "",
      color_font: storeInfo?.theme?.color_font || "",
    },
    store_sub_heading: storeInfo?.store_sub_heading || "",
    store_footer_text: storeInfo?.store_footer_text || "",
    opening_hours:  opening_hours_format,
  };

  const days = {
    monday: null,
    tuesday: null,
    wednesday: null,
    thursday: null,
    friday: null,
    saturday: null,
    sunday: null,
  };

  const [formValue, setFormValue] = useState(form);
  const [loading, setLoading] = useState(false);
  const [moreSetting, setMoreSetting] = useState(false);
  const [newSlotValues, setNewSlotValues] = useState(days);
  const [editActive, setEditActive] = useState(false);

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const docRef = firestore
      .collection(`/${process.env.REACT_APP_FIRESTORE_STORE_PATH}`)
      .doc(merchantKey);

    await docRef.set(
      {
        ...formValue,
      },
      { merge: true }
    );
    setEditActive(false)
    setLoading(false);
  };

  const addNodes = (day, value) => {
    const obj = _.cloneDeep(formValue);

    const obj_oph = obj?.opening_hours;

    obj_oph[day] = [...obj_oph[day], value];

    setFormValue(obj);
    setNewSlotValues((prev) => ({ ...prev, [day]: null }));
  };

  const updateAllNodes = (value) => {
    const obj = _.cloneDeep(formValue);

    const obj_oph = obj?.opening_hours;

    Object.keys(obj_oph).forEach((e) => {
      obj_oph[e] = [...obj_oph[e], value];
    });

    setFormValue(obj);
    setNewSlotValues(days);
  };

  const removeNodes = (day, idx) => {
    const obj = _.cloneDeep(formValue);

    const obj_oph = obj?.opening_hours;

    obj_oph[day] = obj_oph[day]?.filter((e, index) => index !== idx);

    setFormValue(obj);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="form-wrapper">
          <div className="input-box-left">
            <div className="input-boxes " style={{ marginBottom: 0 }}>
              <div className="input-box w-20">
                <label htmlFor="store_name">STORE NAME</label>
                <input
                  type="text"
                  required
                  placeholder="Store Name"
                  id="store_name"
                  className="name-input"
                  value={formValue.store_name}
                  onChange={(e) =>
                    setFormValue({ ...formValue, store_name: e.target.value })
                  }
                />
              </div>

              {/* <div className="input-box">
                <label htmlFor="start_time">STORE TIME START</label>
                <input
                  type="time"
                  required
                  placeholder="Store Start Time"
                  id="start_time"
                  className="name-input"
                  value={formValue.store_start_time}
                  onChange={(e) =>
                    setFormValue({
                      ...formValue,
                      store_start_time: e.target.value,
                    })
                  }
                />
              </div>

              <div className="input-box">
                <label htmlFor="end_time">STORE TIME END</label>
                <input
                  className="name-input"
                  required
                  placeholder="Store End Time"
                  type="time"
                  id="end_time"
                  value={formValue.store_end_time}
                  onChange={(e) =>
                    setFormValue({
                      ...formValue,
                      store_end_time: e.target.value,
                    })
                  }
                />
              </div> */}

              <div className="theme-box w-20">
                <div className="input-box">
                  <div
                    className="picker-color"
                    style={{
                      backgroundColor:
                        formValue?.theme?.color_primary || "transparent",
                    }}
                  ></div>
                  <label htmlFor="end_time">Primary color</label>
                  <input
                    className="name-input"
                    type="text"
                    required
                    placeholder="Store Theme Primary Color"
                    value={formValue?.theme?.color_primary}
                    onChange={(e) =>
                      setFormValue({
                        ...formValue,
                        theme: { color_primary: e.target.value },
                      })
                    }
                  />
                </div>

                <div className="input-box">
                  <div
                    className="picker-color"
                    style={{
                      backgroundColor:
                        formValue?.theme?.color_font || "transparent",
                      border: `1.7px solid ${
                        formValue?.theme?.color_primary || "black"
                      }`,
                    }}
                  ></div>
                  <label htmlFor="end_time">Font color</label>
                  <input
                    className="name-input"
                    required
                    placeholder="Store Theme Text Color"
                    type="text"
                    value={formValue?.theme?.color_font}
                    onChange={(e) =>
                      setFormValue({
                        ...formValue,
                        theme: { color_font: e.target.value },
                      })
                    }
                  />
                </div>
              </div>

              <div style={{ paddingBottom: "1.5rem", paddingLeft: "0.7rem" }}>
                <div
                  className="w-20"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "0.2rem",
                  }}
                >
                  <p className="text-primary-title">STORE OPEN HOURS</p>
                  {!editActive ? (
                    <div
                      className="btn-primary "
                      onClick={() => setEditActive(true)}
                      style={{
                        display: "flex",
                        cursor: "pointer",
                        alignItems: "center",
                        padding: "0.1rem 1.5rem",
                        borderRadius: "60px",
                      }}
                    >
                      {" "}
                      <span>Edit</span>{" "}
                    </div>
                  ) : (
                    <div
                      className="btn-gray "
                      onClick={() => {
                        setEditActive(false);
                        setFormValue((prev) => ({
                          ...prev,
                          opening_hours: form?.opening_hours,
                        }));
                        setNewSlotValues(days)
                      }}
                      style={{
                        display: "flex",
                        cursor: "pointer",
                        alignItems: "center",
                        padding: "0.1rem 1.5rem",
                        borderRadius: "60px",
                      }}
                    >
                      {" "}
                      <span>Cancel</span>{" "}
                    </div>
                  )}
                </div>
                <hr style={{ marginBottom: "0.5rem" }} />
                <div
                  style={{
                    display: "flex",
                    gap: "0.7rem",
                    flexDirection: "column",
                  }}
                >
                  {Object.keys(formValue?.opening_hours)?.map((day, idx) => (
                    <div key={idx}>
                      <div
                        style={{
                          display: "flex",
                          gap: "2rem",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ width: "150px" }}>
                          {capitalizeFirstLowercaseRest(day)}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            gap: "1rem",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "0.5rem",
                            }}
                          >
                            {formValue?.opening_hours[day]?.length > 0 ? 
                            formValue?.opening_hours[day]?.map((hrs, id) =>
                              editActive ? (
                                <div
                                  style={{
                                    border: "1px solid gray",
                                    padding: "0.1rem 0.5rem",
                                    display: "flex",
                                    gap: "1rem",
                                    alignItems: "center",
                                  }}
                                >
                                  {" "}
                                  {hrs}{" "}
                                  <div
                                    onClick={() => removeNodes(day, id)}
                                    style={{ color: "red", cursor: "pointer" }}
                                  >
                                    {" "}
                                    <span>x</span>{" "}
                                  </div>{" "}
                                </div>
                              ) : (
                                <div>{hrs}</div>
                              )
                            ): 
                            <>CLOSED</>
                            }
                          </div>
                          {newSlotValues && newSlotValues[day] && (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  gap: "1rem",
                                  backgroundColor: "lightgray",
                                  padding: "0.3rem 1rem",
                                  borderRadius: "60px",
                                }}
                              >
                                <div>
                                  Start Time
                                  <input
                                    className="name-input"
                                    required
                                    placeholder="Store End Time"
                                    type="time"
                                    id="end_time"
                                    style={{ marginLeft: "0.5rem" }}
                                    value={newSlotValues[day]?.start}
                                    onChange={(e) =>
                                      setNewSlotValues((prev) => ({
                                        ...prev,
                                        [day]: {
                                          ...prev[day],
                                          start: e.target.value,
                                        },
                                      }))
                                    }
                                  />
                                </div>
                                <div>
                                  End Time
                                  <input
                                    className="name-input"
                                    required
                                    placeholder="Store End Time"
                                    style={{ marginLeft: "0.5rem" }}
                                    type="time"
                                    id="end_time"
                                    value={newSlotValues[day]?.end}
                                    onChange={(e) =>
                                      setNewSlotValues((prev) => ({
                                        ...prev,
                                        [day]: {
                                          ...prev[day],
                                          end: e.target.value,
                                        },
                                      }))
                                    }
                                  />
                                </div>
                                <div
                                  onClick={() =>
                                    setNewSlotValues((prev) => ({
                                      ...prev,
                                      [day]: null,
                                    }))
                                  }
                                  style={{
                                    backgroundColor: "lightgray",
                                    cursor: "pointer",
                                    color: "red",
                                  }}
                                >
                                  {" "}
                                  <b>x</b>{" "}
                                </div>
                              </div>
                              <div
                                className="btn-primary "
                                onClick={() =>
                                  addNodes(
                                    day,
                                    `${newSlotValues[day]?.start}-${newSlotValues[day]?.end}`
                                  )
                                }
                                style={{
                                  display: "flex",
                                  cursor: "pointer",
                                  alignItems: "center",
                                  padding: "0.1rem 1rem",
                                  borderRadius: "60px",
                                }}
                              >
                                {" "}
                                <span>Add Slot</span>{" "}
                              </div>
                              <div
                                className="btn-gray  "
                                onClick={() =>
                                  updateAllNodes(
                                    `${newSlotValues[day]?.start}-${newSlotValues[day]?.end}`
                                  )
                                }
                                style={{
                                  display: "flex",
                                  cursor: "pointer",
                                  alignItems: "center",
                                  padding: "0.1rem 1rem",
                                  borderRadius: "60px",
                                }}
                              >
                                {" "}
                                <span>Update All</span>{" "}
                              </div>
                            </>
                          )}
                          {newSlotValues && !newSlotValues[day] && editActive && (
                            <div
                              onClick={() =>
                                setNewSlotValues((prev) => ({
                                  ...prev,
                                  [day]: { start: "00:01", end: "23:59" },
                                }))
                              }
                              className="text-primary-title"
                              style={{
                                padding: "0.3rem 0.6rem",
                                cursor: "pointer",
                                borderRadius: "60px",
                                backgroundColor: "lightgray",
                              }}
                            >
                              {" "}
                              +{" "}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ width: "30rem" }}>
          <hr style={{ marginBottom: "15px" }} />

          <div className="btn-row">
            <div className="check-box">
              <div className="round">
                <input
                  type="checkbox"
                  id="checkbox"
                  checked={!formValue.is_active}
                  onChange={(e) =>
                    setFormValue({ ...formValue, is_active: !e.target.checked })
                  }
                />
                <label htmlFor="checkbox"></label>
              </div>
              <div className="chechbox-name">TURN OFF QUEUING</div>
            </div>

            <a
              style={{ color: "var(--color_primary)" }}
              href={`https://${process.env.REACT_APP_TICKET_HOST_NAME}/${merchantKey}`}
              target="_blank"
              rel="noreferrer"
            >
              View Queue Booking Site{" "}
            </a>
          </div>

          {/* <input type="checkbox" id="store_status"  />
                <label htmlFor="store_status" >TURN OFF QUEUEING</label><br/> */}

          <div className="btn-row">
            <button
              type="submit"
              className="btn btn-primary"
              style={{ marginTop: "30px", width: "13rem" }}
            >
              <b>
                {loading ? (
                  <Loader
                    type="ThreeDots"
                    color="white"
                    height={"12px"}
                    width={"40%"}
                    timeout={6000000}
                  />
                ) : (
                  "SAVE"
                )}
              </b>
            </button>

            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => setMoreSetting(true)}
            >
              <b style={{ color: "var(--color_primary)" }}>
                Edit More Settings
              </b>
            </button>
          </div>
        </div>
      </form>

      {moreSetting && (
        <MoreSettingsPopUp
          storeInfo={storeInfo}
          merchantKey={merchantKey}
          moreSetting={moreSetting}
          setMoreSetting={setMoreSetting}
        />
      )}
    </>
  );
};

export default StoreForm;

const capitalizeFirstLowercaseRest = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};
